import { wrapPageElement } from "./wrapPageElement";

export { wrapPageElement };

export const onRouteUpdate = location => {
  if (location.location.hash) {
    setTimeout(() => {
      const clientWidth = window.innerWidth;
      const item = document.querySelector(`${location.location.hash}`);
      let offsetHeader = window.innerWidth < 1024 ? 64 : 128;
      const offsetTop = item.offsetTop - offsetHeader;
      const sectionPro = document.getElementsByClassName("pro__cards")[0];

      if (location.location.hash === "#pro" && sectionPro && clientWidth < 570) {
        sectionPro.scrollTo({
          top: 0,
          left: 223,
          behavior: "smooth",
        });
      }

      if (location.location.hash === "#request" && clientWidth < 1024) {
        const frameOffset = item.getElementsByClassName("request__frame")[0].clientHeight;
        const itemOffset = frameOffset > 0 ? frameOffset : 0;
        window.scrollTo({
          top: itemOffset + offsetTop,
          left: 0,
          behavior: "smooth",
        });
        return;
      }

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }, 0);
  }
};
