import * as React from "react";
import { Helmet } from "react-helmet";

import "./global.css";
import "./spacings.css";
import "./typography.css";
import "./form.css";
import "./code.css";
import "./shake.css";

export function GlobalStyles() {
  return <Helmet></Helmet>;
}
