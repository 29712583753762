import { useCallback, useEffect } from "react";

declare global {
  interface Window {
    gtag: any;
  }
}

export const useGTag = () => {
  const handleClick = useCallback(event => {
    const data: {
      event_category?: string;
      event_label?: string;
    } = {};

    const action = event.target.dataset["gtagAction"];
    const category = event.target.dataset["gtagCategory"];
    const label = event.target.dataset["gtagLabel"];

    if (!action) return;
    if (category) data["event_category"] = category;
    if (label) data["event_label"] = label;

    typeof window !== "undefined" && window.gtag("event", action, data);
  }, []);

  useEffect(() => {
    // If gtag is blocked on client no sense adding listeners
    if (!window.gtag) {
      return;
    }
    const elements = [...window.document.querySelectorAll("[data-gtag-action]")];

    elements.forEach((el: Element) => {
      el.addEventListener("click", handleClick);
    });

    return () => {
      elements.forEach((el: Element) => {
        el.removeEventListener("click", handleClick);
      });
    };
  }, [handleClick]);
};
