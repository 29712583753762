import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { includeCaptcha } from "lib/captcha";

export function PageMeta() {
  const data = useStaticQuery(graphql`
    query PageMetaQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  React.useEffect(() => {
    includeCaptcha();
  }, []);

  return (
    <Helmet>
      <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />

      <link rel="canonical" href={data.site.siteMetadata.siteUrl} />

      <link rel="preconnect" href="//www.google-analytics.com" />
      <meta name="google-site-verification" content="oJAOUSfYReY-W4r6Sptjxn3mnufVeZCpCmCwS46Xdjc" />
    </Helmet>
  );
}
