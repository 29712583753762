import * as React from "react";

import "intersection-observer";

import { useGTag } from "lib/events";
import { useSmoothScroll } from "lib/scroll";
import { PageMeta } from "components/PageMeta/PageMeta";
import { GlobalStyles } from "components/GlobalStyles/GlobalStyles";

import "./Layout.css";

interface Props {
  children: React.ReactNode;
}

export function Layout({ children }: Props) {
  useSmoothScroll();
  useGTag();

  return (
    <>
      <PageMeta />
      <GlobalStyles />

      <div className="layout t-body-s">{children}</div>
    </>
  );
}
