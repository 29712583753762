import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  // TLDR: The most robust way to turn off sentry
  // See https://github.com/getsentry/sentry-javascript/issues/4509 for details
  dsn:
    process.env.NODE_ENV === "production"
      ? "https://34871467b14a4ad5a9e31a384e656336@o61090.ingest.sentry.io/6190002"
      : undefined,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1,
});
