import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";

if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

export function useSmoothScroll() {
  useEffect(() => {
    if (window.location.href.indexOf("/#") === -1 || window.location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    const shouldReduceMotion = window.matchMedia("(prefers-reduced-motion)").matches;

    if (shouldReduceMotion) {
      return;
    }

    const links = document.querySelectorAll('a[href*="/#"]') as unknown as HTMLAnchorElement[];

    links.forEach(link => {
      link.addEventListener("click", event => {
        const href = (event.target as HTMLLinkElement).href;
        if (href !== window.location.href) return;

        event.preventDefault();
        const target = document.querySelector<HTMLElement>(link.hash);
        if (!target) return;

        const offsetHeader = window.innerWidth < 1024 ? 64 : 128;

        if (link.hash === "#pro" && window.innerWidth < 1024) {
          target.scrollTo({
            top: 0,
            left: 223,
            behavior: "smooth",
          });
        }

        if (link.hash === "#request" && window.innerWidth < 1024) {
          const frameOffset = target.getElementsByClassName("request__frame")[0].clientHeight;
          const itemOffset = frameOffset > 0 ? frameOffset : 0;
          const requestTop = itemOffset + target.offsetTop - offsetHeader;
          window.scrollTo({
            top: requestTop,
            left: 0,
            behavior: "smooth",
          });
          return;
        }

        const offsetTop = target.offsetTop - offsetHeader;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      });
    });
  });
}
